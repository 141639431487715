@media only screen and (max-width: 1023px) {
    /* Login  */
    .login-page {
        width: 100%;
    }

    .login-right {
        display: none;
    }

    .screen {
        width: 100% !important;
        float: left;
        position: relative;
        padding: 85px 20px 0 20px;
        max-height: 59vh;
        overflow: auto;
    }

    .login-left-container {
        margin: 10px !important;
    }

    .login-container {
        width: 90%;
        height: 55%;
        display: flex;
        align-items: center;
    }

    .login-left-content {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

    .login-input {
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
    }

    .signin-button {
        width: 100%;
    }

    .signin {
        width: 100%;
    }

    button.sc-bczRLJ.cHapXD {
        width: 100%;
    }

    button.signin-google-now {
        width: 100%;
    }

    .signin-google {
        width: 100% !important;
    }

    .sign-up {
        margin-top: 14px !important;
    }

    button.sc-bczRLJ.bdxwBt {
        width: 100% !important;
    }

    .welcome-heading {
        width: 100%;
        display: block;
        align-items: flex-start;
        justify-content: flex-start;
    }

    /* Side-Navigation*/
    .main-navigation {
        width: 100%;
        background: #ffffff;
        padding-top: 75px;
        margin: 0;
        scroll-behavior: smooth !important;
    }

    .main-navigation a {
        text-decoration: none;
    }

    /* .main-navigation li {
        margin-top: 4px;
    } */

    .main-navigation li a {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: #ffffff;
        width: 100%;
        height: 40px;
        padding: 20px 28px;
    }

    .main-navigation li a:hover {
        background: #f9fafb;
    }

    .main-navigation li a:hover {
        background-color: #f9fafb;
    }

    .list-drop-down {
        margin: 0px;
        padding: 0px;
    }

    .list-drop-down li {
        list-style: none;
    }

    .list-drop-down li a {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 238px;
        height: 40px;
        padding-left: 32px;
        margin-top: 4px;
        color: #344054;
        border-radius: 6px;
        background: #ffffff;
    }

    .list-drop-down li span {
        padding-left: 16px;
    }

    .navbar-with-drop {
        padding: 24px 0px;
    }

    .nav-item {
        list-style: none;
        margin-bottom: 10px;
    }

    /* a.nav-item1 {
        margin-top: 0px !important;
    } */

    .navbar-expandedview {
        width: 98%;
        float: left;
        background: #ffffff;
        display: flex;
        flex-direction: column;
        max-height: 100vh;
        overflow: auto;
        -webkit-overflow-scrolling: touch;
        scroll-behavior: smooth !important;
    }

    .mobile-menu {
        position: fixed;
        background-color: #667085;
        color: rgb(10, 10, 10);
        top: 0;
        left: 0;
        transform: translateX(-100%);
        transition: all 0.4s;
        z-index: 999;
        height: 100vh;
    }

    .mobile-menu.open {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        transform: translateX(0%);
        width: 100%;
    }

    .closemenu {
        cursor: pointer;
        padding-top: 10px;
    }

    .sidebar-mobile {
        width: 100%;
    }

    .sidebar {
        width: 280px;
        height: 100%;
        float: left;
        background: #fff;
        border: 1px solid #eaecf0;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-direction: column;
        overflow: hidden;
        position: fixed;
    }

    .sidebar-active-icon {
        width: 100%;
    }

    .main-navigation li .sidebar-active-icon a {
        padding: 0 28px;
    }

    .main-navigation li a:hover {
        background-color: #ffffff;
    }

    /* Side-Navigation*/
    /* Searchbar*/
    /* .search-bar {
    width: 290px !important;
    height: 44px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    border-radius: 8px;
    font-size: 16px;
    padding-left: 14px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 20px;
}

.search-bar input {
    border: none;
    outline: none;
    padding: 7px;
}

.search-bar:hover {
    border: 1px solid #d6bbfb;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #f4ebff;
}

.search {
    width: 20px;
    height: 20px;
} */
    /* Sidebar */

    .sidebar {
        display: none;
    }

    .sidebar-navigation-mobile {
        width: 94.8%;
        float: left;
        margin-bottom: 20px;
        position: fixed;
        z-index: 99;
        top: 0;
    }

    .sidebar-navigation-mobile-fixed {
        width: 100%;
        position: fixed;
        z-index: 99;
        top: 0;
    }

    .phd-logo-mobile {
        display: flex;
        align-items: center !important;
        justify-content: center !important;
        padding: 10px 24px 10px 24px;
        background-color: #ffffff;
        box-shadow: 0px 4px 6px rgba(16, 24, 40, 0.05), 0px 0px 0px 0px #f9f8fb;
    }

    .phd-logo {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center !important;
        padding: 0px !important;
        position: relative;
    }

    .hamburger-icon {
        visibility: visible;
        opacity: 10;
        cursor: pointer;
        width: 22px;
    }

    .search-bar {
        width: 140px;
        height: 44px;
        background-color: #fff;
        border: 1px solid #d0d5dd;
        border-radius: 8px;
        font-size: 16px;
        padding-left: 14px;
        display: flex;
        flex-direction: row;
        align-items: center;
        visibility: visible;
        opacity: 10;
    }

    .search-bar input {
        border: none;
        outline: none;
        padding: 7px;
    }

    .search {
        width: 20px;
        height: 20px;
    }

    .search-bar:hover {
        border: 1px solid #d6bbfb;
        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #f4ebff;
    }

    /* Header/Heading */
    .dashboard-heading {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-direction: column;
        margin-bottom: 20px;
        margin-top: 0px !important;
    }

    /* Footer */

    .footer-content {
        padding: 18px 0px 30px 0px;
    }

    .footer-navigation {
        margin: 0px;
        padding: 0px 28px 0px 28px;
        list-style: none;
    }

    .footer-navigation li a {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin: 24px 12px 24px 0px;
    }

    .footer {
        display: flex;
        width: 94% !important;
        padding: 0px !important;
    }

    .footer span {
        font-size: 12px !important;
    }

    .bottom-line-footer {
        width: 100%;
        height: 1px;
        background: #eaecf0;
    }

    .logout-icon {
        cursor: pointer;
    }

    /* Header */
    .breadcrumbs {
        visibility: hidden;
    }

    .header {
        display: none;
    }

    /* Table */
    .table-dash {
        overflow: scroll;
    }

    /* DefaultPageDRC*/
    .default-page {
        margin-top: 20px;
    }

    .default-content-scholar {
        margin-bottom: -30px;
        width: 100%;
    }

    /* AddCampusTabs*/
    .add-campus-tabs {
        cursor: pointer !important;
    }

    /* AddCampusPage-Form*/
    .university-input {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-top: 24px;
    }

    .university {
        width: 100% !important;
        /* margin-top: 10px; */
    }

    .university-name-input {
        width: 100%;
    }

    .heading-buttons {
        margin-top: 20px;
        margin-bottom: 0px !important;
    }

    /* AddMilestone*/
    .invite-supervisor-input {
        align-items: flex-start;
        flex-direction: column;
        margin-top: 0px;
    }

    .supervisor-name {
        margin-right: 0px;
        width: 100% !important;
        margin-top: 20px;
    }

    /* AddDeliverable*/
    .invite-supervisor-content {
        width: 100%;
        float: left;
        /* margin-bottom: 20px; */
    }

    .deliverable-name {
        width: 100% !important;
        margin-top: 18px;
    }

    /* ScholarsListPage*/
    .aasign-supervisor-select {
        margin-top: 0px;
        margin-right: 0px;
    }

    /* DeliverablePage*/
    .invite-deliverable-input {
        margin-top: 12px;
    }

    /* DefaultPages*/
    .default-link-buttons {
        margin-right: 0px;
    }

    /* HeadingLinkButtons*/
    .heading-link {
        background-color: #7f56d9;
        margin-right: 14px;
        font-weight: 300;
    }

    .plus-icon {
        padding-right: 12px;
    }

    .import-icon {
        display: none;
    }

    .heading-buttons a {
        color: #ffffff !important;
        width: auto;
    }

    .heading-link.plus-icon {
        padding-right: 12px;
    }

    .heading-buttons {
        flex-flow: wrap;
        align-items: flex-start;
        justify-content: flex-start !important;
    }

    .export-button {
        flex-flow: wrap;
    }

    .hAJsJC:hover {
        background-color: none !important;
    }

    .nUWBH:hover {
        background-color: none !important;
    }

    /* InviteSupervisor*/
    .supervisor-switch-content {
        display: flex;
        flex-direction: column;
    }

    /* PromoteScholars*/
    .promote-scholar-select {
        margin-top: 20px;
    }

    /* AllForms*/
    .bottom-line {
        display: none;
        width: 100% !important;
        height: 1px;
        background: #eaecf0;
        /* margin-top: 20px; */
    }

    /* Heading*/
    /* .heading-button-mobile .heading-buttons {
        margin: 0 !important;
    } */

    /* Form*/
    .span-text {
        display: none;
    }

    .text-area {
        width: 100%;
    }

    /* Card*/
    .card {
        width: 100%;
        background: #ffffff;
        border: 1px solid #eaecf0;
        box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
            0px 1px 2px rgba(16, 24, 40, 0.06);
        border-radius: 8px;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .card-info {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        padding: 16px;
    }

    .card-info-content {
        display: flex;
        align-items: flex-start !important;
        justify-content: space-between !important;
        padding: 10px 0px;
    }

    .card-info-content span {
        font-size: 14px;
        font-weight: 300;
        color: #344054;
        display: flex;
        align-items: flex-start !important;
        justify-content: flex-start !important;
        width: 60%;
    }

    .card-info-content p {
        font-size: 14px;
        font-weight: 400;
        color: #344054;
        margin-bottom: 0px !important;
        text-align: right !important;
    }

    .card-info-content a {
        text-align: right !important;
    }

    .action-bottom {
        padding: 0px;
    }

    .bottom-line-card {
        width: 100% !important;
        height: 1px;
    }

    .card-actions {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 16px 0px 16px 0px;
    }

    .card-actions a {
        padding-right: 20px;
        font-weight: 500 !important;
    }

    .card-action-edit a {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-right: 10px;
        font-size: 44px;
        text-decoration: none;
        color: #7f56d9;
        cursor: pointer;
    }

    .card-action-edit a:focus {
        color: #5d43a5;
    }

    /* UserProfileModal */
    .userprofileModal.modal.show .modal-dialog {
        max-width: 400px;
    }

    .personal-info-data {
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        margin-top: 20px;
        flex-direction: column;
    }

    .personal-name {
        width: 100% !important;
        margin-right: 0px !important;
    }

    /* UserProfileModal */
    /* AddPatentModal */
    .patent-issue-input {
        margin-top: 18px;
    }

    /* UserProfileModal-CbButtons */
    .cb-buttons-userprofile {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-top: 30px;
    }

    .userprofile-textarea {
        width: 100%;
    }

    /* FeePolicy */
    /* AssignedFeePolicy */
    .assigned-amount {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        /* width: 100%;
        position: fixed;
        border-bottom: 1px solid #C1C7D0;
        border-radius: 0px 0px 3px 3px;
        background: #ffffff;
        z-index: 9999;
        right: 0;
        padding: 20px; */
    }

    .amount-status {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100% !important;
    }

    .fee-policy-buttons {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 30px;
    }

    /* FeePolicy */
    /* AssignFeePolicy-Modal */

    .assign-fee-policy-amount {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        margin-bottom: 0px !important;
    }

    .milestone-fees {
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        margin-bottom: 18px !important;
    }

    .bottom-line-assign-fee-policy {
        width: 300px;
        height: 1px;
        background: #eaecf0;
        margin-top: 10px;
    }

    /* UserProfile-PersonalInfo*/
    /* .progress-bar {
        display: none !important;
    } */

    .profile-screen {
        width: 100%;
        float: right;
        position: relative;
        padding: 0px;
    }

    .personal-name {
        margin-top: 20px !important;
    }

    .dashboard-heading h1 {
        margin-top: 0px !important;
    }

    .personal-info {
        margin-top: 0px !important;
    }

    .personal-info-data {
        margin-top: 0px !important;
    }

    .user-profile-section {
        width: 100%;
        margin-top: -23px;
    }

    .personal-info-upload {
        width: 100%;
        flex-direction: column;
        align-items: center;
    }

    .personal-info-profile {
        width: 100%;
        margin-top: 30px;
    }

    .bottom-line-personal-info {
        width: 300px;
    }

    .user-personal-info-data {
        margin-top: 20px;
    }

    /* UserProfile-SocialSites*/
    .cKsmiX {
        width: 216px !important;
    }

    .MhKFg {
        width: 247px !important;
    }

    /* FeePolicy-Discount*/

    .aasign-supervisor-select {
        width: 318px !important;
    }

    .fee-discount-headings {
        margin-bottom: 10px !important;
    }

    .fee-discount-inputs {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        width: 100%;
        margin-top: -6px;
    }

    /* .fixed-milestone-input {
        display: none;
    } */

    /* .fee-discount-inputs {
        display: none;
    } */

    /* .discount-footer-content {
        display: none;
    } */

    .discount-type-input {
        width: 100%;
    }

    .discount-input {
        width: 100%;
    }

    .after-discount-input {
        width: 100%;
    }

    .fixed-milestone-input {
        width: 100%;
    }

    .fixed-amount-input {
        width: 100%;
        /* margin-bottom: 20px; */
    }

    .fee-discount-content {
        width: 100%;
        flex-direction: column;
    }

    .fixed-amount-input-content {
        width: 100%;
    }

    .sc-bjUoiL.djNyON {
        width: 82%;
    }

    .discount-type-input-content {
        width: 100%;
        margin-top: 24px;
    }

    .discount-input-content {
        width: 100%;
        margin-top: 24px;
    }

    .after-discount-input-content {
        width: 100%;
        margin-top: 24px;
    }

    /* .discount-footer-content {
        display: none;
    } */

    .discount-footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }

    .discount-footer-left {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }

    .bottom-line-discount {
        display: none;
    }

    .bottom-line-discount-mobile {
        width: 320px;
        height: 1px;
        background: #eaecf0;
        margin-top: 20px;
    }

    .discount-footer-content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
    }

    .after-discount-footer {
        flex-direction: column;
        width: 100% !important;
        margin-top: 0px;
    }

    /* CardActions */

    a {
        color: #7f56d9 !important;
        text-decoration: none !important;
    }

    .card-action-delete {
        list-style: none;
        font-size: 14px;
        text-decoration: none;
        color: #f04438 !important;
        cursor: pointer;
    }

    /* HeaderSelectFilter */

    .select-drop-down {
        width: 100% !important;
    }

    .filter-select-drop-down {
        display: flex;
        flex-direction: column !important;
        align-items: flex-start;
        justify-content: space-between;
        width: 100%;
    }

    /* .sc-jSMfEi.bomFbP {
        margin-bottom: 20px !important;
    } */

    /* .sc-jSMfEi.cbLTNH {
        margin-bottom: 20px !important;
    }  */

    .filter-input {
        margin-right: 0px !important;
    }

    .header-filter-input {
        margin-bottom: 20px;
        margin-right: 0px !important;
        margin-top: 0px !important;
        width: 100% !important;
    }

    .header-filter {
        display: flex;
        flex-direction: column;
        margin-top: 20px;
    }

    /*View/Add/EditPages - BackButtons*/

    .nUWBH {
        justify-content: flex-start !important;
    }

    .hAJsJC {
        justify-content: flex-start !important;
        border-radius: none !important;
    }

    a.sc-eCYdqJ.hAJsJC {
        align-items: center;
        justify-content: flex-start !important;
    }

    .sc-eCYdqJ.iPeqyb {
        padding: 0px 0px !important;
    }

    .eQcvkG:focus {
        box-shadow: none !important;
    }

    .bwchtr:focus {
        box-shadow: none !important;
    }

    .view-general-content {
        display: flex;
        flex-direction: column;
    }

    .general-leftcontent p {
        width: 100% !important;
    }

    .back-button a {
        justify-content: flex-start !important;
    }

    /*AddPages*/

    .fiPtPH {
        width: 96% !important;
    }

    .dXNwvb {
        width: 100% !important;
    }

    /* Pagination */

    .pagination-content {
        width: 100% !important;
        display: flex;
        flex-direction: column;
    }

    .pagination-right {
        width: 100% !important;
        margin-top: 20px;
    }

    .pagination {
        width: 100% !important;
    }

    .select-items-pagination {
        width: 100% !important;
    }

    .select-items-pagination select {
        padding-left: 170px;
    }

    nav {
        width: 100% !important;
    }

    li.page-item.border-radius-left.disable {
        width: 50%;
    }

    li.page-item.border-radius-right.disable {
        width: 50%;
    }

    li.page-item.border-radius-left {
        width: 50%;
    }

    li.page-item.border-radius-right {
        width: 50%;
    }

    .arrows-page {
        display: flex;
        padding-top: 2px;
    }

    .arrows-page-next {
        display: flex;
        padding-top: 2px;
        justify-content: flex-end;
    }

    .arrows-page span {
        align-items: flex-end;
        justify-content: flex-end;
        display: flex;
        /* width: 54%; */
    }

    .arrows-page span1 {
        align-items: flex-end;
        justify-content: flex-end;
        display: flex;
        /* width: 70%; */
    }

    td {
        padding: 0px !important;
    }

    /* Form-Input */

    .fTckCf {
        width: 96% !important;
    }

    /* Form-Textarea */

    .fRgToT {
        width: 96% !important;
    }

    /* MilestonePage */

    .aasign-supervisor-select {
        width: 100% !important;
    }

    /* PromoteScholarPage */

    .promote-supervisor-select {
        width: 100% !important;
        margin-right: 0px !important;
    }

    .promote-scholar-select {
        width: 100% !important;
    }

    .cb-buttons-scholars {
        margin-top: 20px !important;
        display: flex;
        align-items: flex-start !important;
        justify-content: flex-start !important;
    }

    .cb-buttons {
        margin-right: 0px;
        margin-bottom: 20px;
    }

    /* AddInput-Page */

    .sc-gsnTZi.fTckCf {
        width: 100% !important;
    }

    .sc-iBkjds.fRgToT {
        width: 100% !important;
    }

    .card-right-info a {
        font-weight: 500 !important;
    }

    .card-right-info td a {
        text-align: right !important;
    }

    td {
        text-align: right;
    }

    /* AddSupervisorPage  */

    .invite-supervisor-inputs {
        margin-top: 0px !important;
        flex-direction: column;
    }

    /* AddPagesSimpleInput */

    .sc-gsnTZi.fiPtPH {
        width: 100% !important;
    }

    /* LoginFormInput */

    .sc-dkzDqf.gLnEbK {
        width: 100% !important;
    }

    .sc-dkzDqf.cxlnQI {
        width: 100% !important;
    }

    .login-input-field {
        width: 100% !important;
    }

    /* Card-NoRecordFound */

    .no-records-incard {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 20px;
    }

    /* AddFeePolicy */

    .add-fee-policy-field {
        flex-direction: column;
    }

    .choose-milestone-amount {
        width: 100%;
    }

    .sc-ksZaOG.coGEWo {
        width: 168px;
    }

    .enter-fee-amount {
        width: 100%;
        margin-top: 20px;
    }

    .add-fee-policy-input { 
        flex-direction: column;
    }

    .milestone-fixed-input {
        width: 100%;
    }

    .choose-milestone-amount {
        margin-top: 20px;
    }

    .collection-button {
        margin-right: 20px;
    }

    .eJwOae {
        padding: 0px !important;
    }

    /* TextEditor-Description */

    .rdw-editor-toolbar {
        width: 100%;
    }

    .rdw-editor-main {
        width: 100%;
    }

    /* SideMenu */

    .navbar-expandedview {
        width: 98%;
        float: left;
        background: #ffffff;
        display: flex;
        flex-direction: column;
        max-height: 59vh;
        overflow: auto;
        -webkit-overflow-scrolling: touch;
    }

    .user-info {
        width: 100%;
    }

    /*DeleteModal*/

    .modal-dialog-centered {
        min-height: calc(100% - var(--bs-modal-margin) * 15) !important;
    }

    /* BackButton */

    .view-back-button {
        display: flex !important;
    }

    .back-button a:focus {
        box-shadow: none !important;
    }

    .heading-buttons a.view-back-button {
        padding: 0px !important;
        box-shadow: none !important;
        background-color: #ffffff !important;
    }

    .back-button a.view-back-button {
        padding: 0px !important;
    }

    /* UploadDragDrop */

    .file-upload {
        height: 18vh;
    }

    .uploaded-images-size {
        width: 100px;
        height: 100px;
    }

    /* FeeWaiver-Modal */

    .discountModal.modal.show .modal-dialog {
        max-width: 608px;
    }

    .personal-info-about {
        margin-top: 20px;
    }

    /* Pagination-ForTab */

    .select-items-pagination span {
        width: 90%;
    }

    .select-items-pagination select {
        padding-left: 16px;
    }
}

@media only screen and (max-width: 1536px) {
    .screen {
        max-height: 69vh;
    }

    .navbar-expandedview {
        max-height: 69vh;
    }
}

@media only screen and (max-width: 767px) {
    input[type='date']:before {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    input[type='date'] {
        position: relative;
    }

    .screen {
        width: 100% !important;
        float: left;
        position: relative;
        padding: 85px 20px 0 20px;
        max-height: 100vh;
        overflow: auto;
    }

    .navbar-expandedview {
        width: 98%;
        float: left;
        background: #ffffff;
        display: flex;
        flex-direction: column;
        max-height: 90vh;
        overflow: auto;
        -webkit-overflow-scrolling: touch;
    }

    .sidebar-navigation-mobile {
        width: 89%;
    }
/* 
    .cb-buttons {
        margin: 0px 0px 20px 0px;
    } */

    .login-container {
        width: 90%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .login-left-content {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .eJwOae {
        padding: 0px !important;
    }
    /*Form-AddPages */

    .university-input-heading {
        width: 100%;
        padding-bottom: 8px !important;
    }

    .sc-gueYoa.kLDaGu {
        margin-top: 0px !important;
    }

    /* TextEditor-Description */

    .rdw-editor-toolbar {
        width: 100%;
    }

    .rdw-editor-main {
        width: 100%;
    }

    /* DateField */

    input[type='date'] {
        padding: 10px !important;
    }

    input[type='date']::before {
        position: absolute !important;
        text-align: center !important;
    }

    /* UploadDragDrop */

    .file-upload {
        padding: 20px !important;
    }

    /* HomePageLoader */

    .home-page-loader {
        right: 38%;
    }

    /* HeadingButton-Export */

    .export-button {
        margin-left: 0px;
    }

    .export-button-link {
        border: 1px solid #eaecf0;
        border-radius: 6px;
        width: 46px;
        height: 40px;
        background-color: #ffffff;
        display: flex;
        align-items: center !important;
        justify-content: center !important;
        flex-flow: wrap;
        cursor: pointer;
    }

    .export-button-link:hover {
        background-color: #f9fafb;
    }

    /* RemovedImport-Button */

    /* .import-scholar {
        display: none !important;
    } */

    .import-staff {
        display: none !important;
    }

    .import-supervisor {
        display: none !important;
    }

    /* UploadImage-Profile */

    .dummy-no-profile-image {
        width: 100px;
        height: 100px;
        margin-bottom: 20px;
    }

    .uploaded-images-col {
        margin: 0px 0px 20px 0px;
    }

    .no-profile-image {
        margin-right: 0px !important;
    }

    .click-to-upload {
        width: 100%;
    }

    /* BackButton  */

    .back-button a:focus {
        box-shadow: none !important;
    }

    /* CollectionModal */

    .modal-box {
        align-items: flex-start;
    }

    /* ForgotPage */

    .forgot-back-to-login {
        width: 100% !important;
    }

    .scrollable-element {
        scrollbar-color: red yellow !important;
    }

    /* ScrollbarColorChange */

    ::-webkit-scrollbar {
        width: 0.1rem;
    }

    ::-webkit-scrollbar-track {
        background-color: #eaecf0;
    }

    /* FeeWaiver */

    .fee-policy-modal {
        margin: 26px;
    }

    /* .fee-waiver {
        background: #F9FAFB;
        border-radius: 8px;
        padding: 0px 20px 20px 20px;
        display: flex;
        flex-direction: column;
        margin-top: 20px;
    } */
    /*
    .fee-waiver-content {
        display: flex;
        flex-direction: column;
        margin-top: 20px;
    }

    .fee-waiver-heading {
        font-weight: 500;
        font-size: 14px;
        color: #101828;
        margin-bottom: 10px;
    } */

    /* .bottom-line-fee-waiver {
        width: 280px;
        height: 1px;
        background: #eaecf0;
        margin-top: 24px;
    } */

    /* .fee-waiver-footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 20px;
    }

    .total-fee h4 {
        font-weight: 700;
        font-size: 20px;
        color: #101828;
    } */

    /* .total-amount-fee h4 {
        font-weight: 700;
        font-size: 20px;
        color: #7f56d9;
    }

    .pagination {
        width: 100%;
    } */

    .fee-waiver {
        margin-top: 20px;
    }

    .fee-waiver-heading {
        padding: 6px 0px 6px 0px;
    }

    .sc-fbJfA.iBTkJH {
        margin-bottom: 20px;
    }

    .sc-jTrPJq.hwmWQN {
        margin-bottom: 10px;
    }

    /* .bottom-line-fee-waiver {
        width: 100px;
    } */

    /* UserProfile-MobView */

    .progress-bar {
        width: 100%;
        overflow-x: scroll !important;
    }

    ::-webkit-scrollbar {
        display: none;
    }

    ::-webkit-scrollbar-thumb:horizontal {
        background: #7f56d9;
    }

    .progress-bar-content ul {
        display: flex;
    }

    .personal-info-tab {
        margin-right: 10px;
        padding-right: 6px;
        flex-direction: column-reverse;
    }

    .profile-heading {
        margin-top: 30px;
    }

    .user-click-to-upload {
        flex-direction: column;
    }

    .personal-info {
        border: none;
        padding: 0px;
    }

    .personal-info-tab {
        margin: 0px 16px -6px 0px !important;
    }

    .progress-complete {
        padding-right: 12px !important;
    }

    .personal-info .file-upload-admin {
        width: 100%;
    }

    .file-upload-admin {
        width: 330px;
    }

    .user-file-upload-info {
        margin-top: 10px;
    }

    .personal-info-tab:active {
        color: #7f56d9;
    }

    .personal-info-tab span:active {
        color: #7f56d9 !important;
    }

    .personal-info-tab span {
        padding-left: 0px !important;
        padding-bottom: 4px;
    }

    .profile-status-line .progress-complete {
        visibility: visible !important;
    }

    .progress-complete {
        display: flex;
    }

    .profile-status-line {
        display: flex !important;
        padding-top: 10px;
    }

    .profile-form-container {
        flex-direction: column;
        margin-bottom: 30px;
    }

    .profile-form-container > div {
        margin-bottom: 0px;
        padding-right: 0px;
    }

    /* ShowDropdownIcon */

    .select-items-pagination select {
        background: #fff !important;
        height: 38px !important;
    }

    .select-items-pagination select,
    .select-items-pagination span {
        height: 38px !important;
    }

    /* AddPages-CalendarIcon */

    /* input[type='date']:before {
        top: 0px !important;
    } */

    /* AddProfileForm-Qualification */

    .generic-profile-form {
        margin-top: 18px;
    }

    .profile-form-container {
        margin-top: 0px !important;
    }

    /* AddForm-Pages */

    .cb-button-save-create-another {
        display: flex;
        align-items: flex-end !important;
        justify-content: flex-end !important;
        flex-direction: column;
        float: right;
    }

    .save-create-another {
        margin-right: 0px;
        margin-bottom: 30px;
        float: right;
    }

    button.sc-beqWaB.hHvUKz {
        margin-right: 0px !important;
    }

    button.sc-beqWaB.ehEROx {
        margin-right: 0px !important;
    }

    /* ScholarHomePage-Mobile */

    .scholar-home-page  {
        display: flex;
        flex-direction: column-reverse;
    }

    .scholar-name {
        display: flex;
        flex-direction: column-reverse;
    }

    .scholar-heading {
        margin: 20px 30px 30px 30px;
    }

    .scholar-home-main {
        display: flex;
        flex-direction: column;
    }

    .scholar-home-heading {
        padding-bottom: 10px;
    }

    .scholar-dashboard-content {
        margin-top: 10px;
    }

    .scholar-home {
        display: flex;
        flex-direction: column;
        margin: 0px 30px 0px 30px;
    }

    .scholar-card {
        width: 100%;
        margin: 0px 30px 20px 0px;
    }

    /* DeliverableDetail-Page */

    .bottom-line-deliverables {
        width: 340px;
        display: none;
    }

    .scholar-fee-icon {
        padding: 20px;
    }

    .scholar-fee-icon h3 {
        font-size: 16px;
    }

    .scholar-card-info {
        padding: 10px 0px 10px 20px;
    }

    .scholar-card-icon {
        width: 40px;
    }

    /* .scholar-home-main {
        display: none;
    } */

    /* Scholar-SidebarNavigation */

    .topnav {
        display: none;
    }

    .scholar-sidebar-navigation-mobile-fixed {
        width: 100%;
        position: fixed;
        z-index: 99;
        top: 0;
    }

    .scholar-phd-logo-mobile {
        display: flex;
        align-items: center !important;
        justify-content: center !important;
        padding: 20px 24px 10px 24px;
        background-color: #7f56d9;
        /* box-shadow: 0px 4px 6px rgba(16, 24, 40, 0.05), 0px 0px 0px 0px #f9f8fb; */
    }

    .scholar-sidebar-navigation-mobile {
        width: 100%;
        float: left;
        margin-bottom: 20px;
        position: fixed;
        z-index: 99;
        top: 0;
    }

    .scholar-navigation {
        display: none;
    }

    .scholar-nav-right {
        display: none;
    }

    .scholar-phd-logo {
        align-items: center;
        justify-content: center;
    }

    .scholar-phdpad-logo {
        width: 150px;
        padding-left: 0px !important;
    }

    .scholar-closemenu {
        background-color: #7f56d9;
        z-index: 999;
        flex: 0;
        position: absolute;
        right: 11px;
        top: 10px;
    }

    .scholar-hamburger-icon {
        visibility: visible;
        opacity: 10;
        cursor: pointer;
        width: 26px;
    }

    .scholar-navbar-expandedview {
        width: 100%;
        float: left;
        background: #7f56d9;
        display: flex;
        flex-direction: column;
        max-height: 100vh;
        overflow: auto;
        -webkit-overflow-scrolling: touch;
    }

    .scholar-mobile-menu {
        position: fixed;
        background-color: #7f56d9;
        color: rgb(10, 10, 10);
        top: 0;
        left: 0;
        transform: translateX(-100%);
        transition: all 0.4s;
        z-index: 999;
        height: 100vh;
    }

    .scholar-mobile-menu.open {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        transform: translateX(0%);
        width: 100%;
        position: fixed;
        z-index: 999999;
    }

    .scholar-closemenu {
        cursor: pointer;
    }

    .scholar-main-navigation {
        width: 100%;
        padding-top: 50px;
        margin: 0;
        scroll-behavior: smooth !important;
    }

    .scholar-main-nav-inner-content ul {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-left: 0px !important;
        font-size: 24px;
        margin-bottom: 50px;
    }

    .scholar-sidebar-mobile {
        width: 100%;
    }

    .scholar-main-nav-inner-content a ul {
        color: #ffffff;
    }

    .scholar-main-nav-inner-content ul {
        color: #ffffff;
    }

    /* Scholar-FeeDash */

    .scholar-fee-dash {
        margin: 30px 30px 0px 30px;
    }

    /* Scholar-Deliverable */

    .assign-deliverable-content {
        display: flex;
        flex-direction: column;
    }

    .scholar-progress-deliverable {
        flex-direction: column;
        width: 100%;
    }

    .deliverable-detail-right {
        margin: 20px 0px 0px 0px;
    }

    .bottom-line-deliverable {
        width: 350px;
    }

    .scholar-personal-profile {
        margin: 20px 30px 0px 30px;
    }

    /* FeeWaiver-InputsMob */

    .fee-discount-headings {
        display: none;
    }

    .fee-wavier-mob-input {
        display: none;
    }

    .fee-wavier-mob-input-heading {
        font-size: 14px;
        margin-top: 20px;
    }

    .fee-wavier-mob-amount-input-heading {
        font-size: 14px;
        margin-top: 20px;
    }

    .fee-wavier-mob-input-headings {
        font-size: 14px;
        margin-top: 40px;
        color: #7f56d9;
    }

    /* .sc-iAEyYk.kBszis {
        margin: 0px 0px 40px 0px !important;
    } */

    /* ScholarProfile: DeliverableDetail-Page */

    .dropdown {
        width: 330px;
    }

    .deliverable-sub-detail-dropdown {
        width: 330px;
    }

    .research-icon {
        padding: 20px 0px 20px 20px;
    }

    .drodown-sub-content p {
        width: 100%;
    }

    .dropdown-header-color-change {
        width: 99% !important;
    }

    .sub-detail-left {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
    }

    span.pending {
        padding: 10px 0px 10px 20px;
    }

    .deliverable-sub-detail-dropdown h4 {
        padding: 0px 10px 0px 20px;
    }

    .dropdown {
        display: flex;
        align-items: flex-start;
    }

    .dropdown-icon {
        padding: 32px 26px 0px 0px;
    }

    .dropdown-content p {
        padding: 10px 0px 0px 10px;
    }

/* SubmissionHome-Scholar */

    .submission-home {
        margin: 0px;
    }

    .bottom-line-submission {
        width: 350px;
    }

    .deliverable-submission {
        margin: 20px;
    }

    .university {
        width: 90%;
    }

    .research-icon {
        display: none;
    }

    .sub-detail-left h4 {
        padding: 20px 0px 10px 20px;
    }

    span.completed {
        padding: 0px 0px 20px 20px;
    }

    .completed-tick {
        padding: 30px 20px 0px 0px;
    }

    .dropdown-content p {
        padding: 6px 10px 6px 10px;
    }

    button.sc-gLDzan.jYljFF {
        margin: 0px 20px 0px 0px;
    }

    button.sc-gLDzan.cmHHIg {
        margin: 0px 40px 0px 0px;
    }

    /* Resume */

    .profile-resume-personal-content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
    }

    .profile-resume-image {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
    }

    .profile-resume-image-content {
        margin: 20px 0px 0px 0px;
    }

    .profile-resume-right p {
        width: 100%;
    }

    .profile-resume-container {
        margin: 40px;
    }

    .resume-inner-details a {
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
    }

    /* FeeReceipt */

    .main-container-chitkara-logo {
        margin: 40px 0px 0px 40px;
    }

    .receipt-content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        margin: 40px 40px 0px 40px;
    }

    .receipt-info {
        margin-bottom: 10px;
    }

    .student-data {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        margin: 0px 40px 0px 40px !important;
    }

    .student-content {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        width: 100% !important;
    }

    .receipt-table {
        margin: 40px 40px 0px 40px;
    }

    .total-amount-content {
        margin: 40px 40px 0px 40px;
    }

    .amount-in-words {
        margin: 40px 40px 0px 40px;
    }

    .receipt-footer h4 {
        margin: 40px;
        text-align: center;
    }

    .bottom-line-receipt {
        width: 370px;
        margin: 20px;
    }

    .receipt-footer-content {
        margin: 0px;
    }

    .receipt-footer-content p  {
        width: 100%;
    }

    .receipt-footer-content {
        margin: 20px;
    }

    /* .student-personal-info {
        width: 40%;
    } */


    /* Scholar-Settingspage */

    .cb-buttons-settings {
        margin-bottom: 40px;
    }

/* AddQualification-ScholarProfile */

    .eZnrH {
        margin-bottom: 0px;
    }

    .profile-form-container button.sc-gLDzan.cmHHIg {
        margin-right: 0px !important;
    }

    .sc-iAEyYk.kBszis {
        margin-bottom: 0px !important;
    }

    button.sc-gLDzan.cmHHIg {
        margin: 0px 20px 0px 0px !important;
    }

    /* AddAcademicYear-CalendarField */

    input::-webkit-date-and-time-value {
        text-align: left !important;
    }

    /* AssignFeePolicy-ViewPage-DownloadOption */

    .actions {
        padding-right: 0px !important;
    }

    .actions a  {
        padding-right: 0px !important;
    }

    /* Scholar-SubmisisonPage */

    .submission-heading {
        margin: 30px 30px 0px 30px;
    }

    /* Fee-Receipt */

    .fee-receipt-download-pdf {
        justify-content: flex-start;
    }

    .fee-receipt-download-pdf a {
        margin: 40px 0px 0px 40px;
    }

    .main-wrapper {
        width: 100%;
    }

    .embed-responsive {
        position: relative;
        display: block;
        width: auto !important;
        height: 0;
        padding: 0;
        overflow: hidden;
    }

    /* LoginPage-InputFieldsHeight */

    .sc-dmqHEX.hxTotu {
        height: 44px;
    }

   /* Scholar-HomePage */

    .scholar-name h4 {
        display: none;
    }

    .scholar-name-heading h4 {
        margin-bottom: 30px;
    }


    .profile-form-container>div {
        width: 100% !important;
    }

    .generic-profile-form-modal {
        width: 100% !important;
    }

    /* UserProfile-Modal-Scholar */

    .userprofile-modal {
        margin: 26px;
    }

    /* Add Academic Year - DateInputField */

    input.sc-fqkvVR.fUinre {
        appearance: none !important;
    }

    input.sc-fqkvVR.cBlcIW {
        appearance: none !important;
    }

    /* ScholarFeeMobile */

    .scholar-fee-mobile {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 70%;
    }

    .scholar-fee-mobile span {
        font-size: 16px;
    }

    .fee-pay-now-scholar {
        width: 100%;
        position: fixed;
        bottom: 0;
        border-top: 1px solid #C1C7D0;
        border-radius: 0px 0px 3px 3px;
        height: 80px;
        background: #ffffff;
        z-index: 9999;
        right: 0;
    }

    .fee-pay-now-scholar-header {
        width: 100%;
        position: fixed;
        bottom: 0;
        border-top: 1px solid #C1C7D0;
        border-radius: 0px 0px 3px 3px;
        height: 80px;
        background: #ffffff;
        right: 0;
        z-index: 9999;
    }

    .pay-now-scholar {
        margin: 20px;
    }

    .collection-button {
        display: none;
    }

    .assigned-fee-policy-amount  {
        width: 100%;
    }

    .assigned-fee-policy-amount {
        margin-bottom: 0px;
    }

    .scholar-fee-status-tab {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        margin-right: 20px;
    }

    .scholar-fee-status-tab span {
        color: #475467;
        font-size: 16px;
    }

    .scholar-fee-status-tab span:hover {
        color: #7f56d9;
    }
    
    .scholar-fee-status-tab span:active {
        color: #7f56d9;
    }

    /* Assign Roll Number - Modal */

    .fee-mode-name { 
        width: 100% !important;
    }

    .assign-roll-no-input {
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
    }


}
